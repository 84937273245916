import React from "react";
import './Navbar.css'

export const Navbar = () => {
    return (
        <>
            <ul className="navbarMenu">
                <li><a href="">Home</a></li>
                <li><a href="">Products</a></li>
                <li><a href="">About</a></li>
                <li><a href="">Contact</a></li>
            </ul>
        </>
    )
}
