import React from "react";
import './Welcome.css';

export const Welcome = () => {
    return (
        <>
            <h2 className="title-welcome">Bienvenidos a mi página en React</h2>
        </>
    )
}
