import React from "react";
import ImgLogo from "../../../Img/Logo.png";
import './Logo.css'
export const Logo = () => {
    return (
        <>
            <img className="Logo" src={ImgLogo} alt="LOGO" />
        </>
    )
}
