import React from "react";
import './Features.css'

export const Features = () => {
    return (
        <>
            <h4 className="text-features">Recordemos algunas características</h4>
        </>
    )
}
