import React from "react";
import './Credits.css';

export const Credits = () => {
    return (
        <>
            <p className="text-credits">Desarrollado por: <br/>
            Cristian Leandro Perez Pelaez - ADSI 2184066</p>
            
        </>
    )
}
